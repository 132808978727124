
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  faEdit,
  faEye,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { I18NextPipe } from "angular-i18next";
import {
  CrudNetFilterExpression,
  CrudNetSearchRequest,
  CrudNetUpdateRequest,
  CrudNetViewMode,
  TypeaheadSearch,
} from "crudnet-amgular";
import { map } from "rxjs/operators";
import { ArchivioCandidatiService } from "../archivio-candidati.service";
import { forkJoin, Observable, of } from "rxjs";
import * as moment from "moment";

@Component({
  selector: "app-archivio-candidati-custom-crud",
  templateUrl: "./archivio-candidati-custom-crud.component.html",
  styleUrls: ["./archivio-candidati-custom-crud.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ArchivioCandidatiCustomCrudComponent implements OnInit{
  /**
   * Crudnet repo for table or view
   */
  @Input() service: ArchivioCandidatiService;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;
  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  viewModes = CrudNetViewMode;
  defaultCrudValues;
  customSubmitParams: any[] = [];
  currentIcon = faPlus;
  closeIcon = faTimes;
  hideTitle = false;

  /* SCHEDA ANAGRAFICA */
  nomeCandidato = null;
  cognomeCandidato = null;
  dataNascita = null;
  telefono = null;
  mail = null;
  provinciaResidenza = null;
  comuneResidenza = null;
  sediValutate = null;
  profilo = null;
  tipologiaProfilo = null;
  preavviso = null;
  seniority = null;
  tipoContrattoAttuale = null;
  fileCv = null;
  note = null;
  _104 = null;
  ralAttuale = null;
  skillList = null;
  skillPrimarie = null;
  ralDesiderataDa = null;
  ralDesiderataA = null;
  file = null;
  lingueList = null;
  lingueRichieste = null;
  blackList = null;

  loading = true;


  constructor(public translatePipe: I18NextPipe) {}


  ngOnInit() {
    this.setSkill();
    this.setLingue();

    if (this.viewMode === this.viewModes.EDIT && this.row) {
      this.row.szSkillsArray = [
        ...(this.row.CandidatoSkill_List
          ? this.row.CandidatoSkill_List.map((el) => el.Skill)
          : []),
      ];

      this.row.szLinguaArray = [
        ...(this.row.CandidatoLingua_List
          ? this.row.CandidatoLingua_List.map((el) => el.Lingua)
          : []),
      ];

      this.service.find(this.idCurrent).subscribe((res) => {
        const getProvincia = this.service.getValueForSelect(
          res.result[0].IDPROVINCIA,
          "Provincia"
        );
        const getComune = this.service.getValueForSelect(
          res.result[0].IDCOMUNE,
          "Comune"
        );
        const getTipoContrattoLavoro = this.service.getValueForSelect(
          res.result[0].IDTIPOCONTRATTOLAVORO,
          "TipoContrattoLavoro"
        );
        const getProfilo = this.service.getValueForSelect(
          res.result[0].IDTIPOPROFILOLAVORATIVO,
          "TipoProfiloLavorativo"
        );

        forkJoin([
          getProvincia,
          getComune,
          getTipoContrattoLavoro,
          getProfilo,
        ]).subscribe(
          (el) => {
            this.loading = false;
            this.provinciaResidenza = el[0].result;
            this.comuneResidenza = el[1].result;
            this.tipoContrattoAttuale = el[2].result;
            this.profilo = el[3].result;
            this.lingueRichieste = this.row.szLinguaArray;
            this.nomeCandidato = this.row.Anagrafica.nome;
            this.skillPrimarie = this.row.szSkillsArray;
            this.cognomeCandidato = res.result[0].COGNOME;
            this.dataNascita = moment(res.result[0].DATANASCITA).toDate();
            this.telefono = res.result[0].CELLULARE;
            this.mail = res.result[0].EMAILPRIVATA;
            this.sediValutate = res.result[0].SEDIVALUTATE;
            this.tipologiaProfilo = res.result[0].TIPOLOGIAPROFILO;
            this.preavviso = res.result[0].GIORNIPREAVVISO;
            this.seniority = this.row.TipoSeniority;
            this.note = res.result[0].NOTE;
            this._104 = res.result[0].FLAG104;
            this.ralAttuale = res.result[0].RAL;
            this.ralDesiderataDa = res.result[0].RALDA;
            this.ralDesiderataA = res.result[0].RALA;
          },
          () => {},
          () => (this.loading = false)
        );
      });
    } else {
      this.loading = false;
    }
    this.currentIcon = this.getIcon();
  }

  resetComune(event) {
    if (!this.provinciaResidenza) {
      this.comuneResidenza.displayValue = " ";
      this.loading = false;
    }
  }

  tipoSeniorityDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      includes: [],
      pageSize: -1,
      filter,
    };
    return this.service.searchLookup(searchRequest, "TipoSeniority").pipe(
      map((res) => {
        return res.result;
      })
    );
  };

  tipoProfiloDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      includes: [],
      pageSize: -1,
      filter,
    };
    return this.service
      .searchLookup(searchRequest, "TipoProfiloLavorativo")
      .pipe(
        map((res) => {
          return res.result;
        })
      );
  };

  tipoContrattoAttualeDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      includes: [],
      pageSize: -1,
      filter,
    };
    return this.service.searchLookup(searchRequest, "TipoContrattoLavoro").pipe(
      map((res) => {
        return res.result;
      })
    );
  };

  tipoProvinciaResidenzaDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.service.searchLookup(searchRequest, "Provincia").pipe(
      map((res) => {
        return res.result;
      })
    );
  };

  tipoComuneResidenzaDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0) && fkIdProvincia = @1",
        expressionValues: [
          { value: typedValue },
          { value: this.provinciaResidenza.idProvincia },
        ],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.service.searchLookup(searchRequest, "Comune").pipe(
      map((res) => {
        return res.result;
      })
    );
  };

  setSkill() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    this.service.searchLookup(searchRequest, "Skill").subscribe((res) => {
      this.skillList = res.result;
    });
  }

  setLingue() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    this.service.searchLookup(searchRequest, "Lingua").subscribe((res) => {
      this.lingueList = res.result;
    });
  }

  mapToServer() {
    this.skillPrimarie = [
      ...(this.skillPrimarie ? this.skillPrimarie.map((el) => el.idSkill) : []),
    ].join(",");

    this.lingueRichieste = [
      ...(this.lingueRichieste
        ? this.lingueRichieste.map((el) => el.idLingua)
        : []),
    ].join(",");

       if (this.fileCv.length && this.fileCv[0].data && this.fileCv[0].data.indexOf("base64,") >= 0) {
      this.file = this.fileCv[0].data.split("base64,")[1];
    }

    if (this.viewMode === this.viewModes.DELETE && this.row) {
      return {
        idCandidato: this.idCurrent,
      };
    } else {
      let app: any = {
        idCandidato: this.idCurrent,
        nome: this.nomeCandidato,
        cognome: this.cognomeCandidato,
        dataNascita: this.dataNascita ? moment(this.dataNascita, "DD/MM/YYYY").format("YYYY/MM/DDTHH:mm:ss").toString() : null,
        cellulare: this.telefono ? this.telefono : null,
        emailPrivata: this.mail ? this.mail : null,
        idProvinciaResidenza: this.provinciaResidenza && this.provinciaResidenza.idProvincia ? this.provinciaResidenza.idProvincia : null,
        idComuneResidenza: this.comuneResidenza && this.comuneResidenza.idComune ? this.comuneResidenza.idComune : null,
        sediValutate: this.sediValutate ? this.sediValutate : null,
        ralAttuale: this.ralAttuale,
        ralDesiderataDa: this.ralDesiderataDa,
        ralDesiderataA: this.ralDesiderataA,
        idProfilo: this.profilo.idTipoProfiloLavorativo,
        tipologiaProfilo: this.tipologiaProfilo ? this.tipologiaProfilo : null,
        giorniPreavviso: this.preavviso ? this.preavviso : null,
        idTipoSeniority: this.seniority && this.seniority.idTipoSeniority ? this.seniority.idTipoSeniority : null,
        idTipoContrattoLavoro: this.tipoContrattoAttuale && this.tipoContrattoAttuale.idTipoContrattoLavoro ? this.tipoContrattoAttuale.idTipoContrattoLavoro : null,
        flag104: this._104 ? 1 : 0,
        note: this.note ? this.note : null,
        szSkillsArray: this.skillPrimarie,
        szLinguaArray: this.lingueRichieste,
      };
      if (this.viewMode === this.viewModes.CREATE) {
        app.curriculumVitae = this.file;
      }
      return app;
    }
  }

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  onSubmit() {
    const entity = this.mapToServer();
    const p = new CrudNetUpdateRequest();
    let obj = new Observable<any>();

    p.entity = entity;
    console.log(p.entity);
    if (this.viewMode === CrudNetViewMode.CREATE) {
      obj = this.service.add(p);
    } else if (this.viewMode === CrudNetViewMode.EDIT) {
      obj = this.service.update(p);
    } else if (this.viewMode === CrudNetViewMode.DELETE) {
      obj = this.service.del(p);
    }
    obj.subscribe((res) => {
      if (res.error) {
        this.error.emit(res.message);
      } else {
        this.success.emit("OK");
      }
      this.close.emit();
    });
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }
}
