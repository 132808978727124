import { Observable } from "rxjs";
import { CrudNetExecRequest, CrudNetSearchRequest, CrudNetUpdateFieldRequest, CrudNetUpdateRequest } from "crudnet-amgular";
import { BaseRepo } from "src/app/repos/BaseRepo";
import { Injectable } from "@angular/core";
import { CrudNetBaseResponse, CrudNetResultResponse, transformFiltersToServer, transformServerFilterToTableFilter } from "projects/crudnet-amgular/src/public-api";
import { TableServerFilter, TableServerFilterCondition, TableServerFilterType } from "utils";



@Injectable({
  providedIn: "root",
})
export class ArchivioCandidatiService extends BaseRepo<any> {

  getTable(): string {
    return "Candidato";
  }

  search(
    params: CrudNetSearchRequest<any>,
    customTable?: any
  ): Observable<CrudNetResultResponse<any>> {
    if (!customTable) {
      let paramFilter = transformServerFilterToTableFilter(params.filter);
      let filter = new TableServerFilter();

      filter.data = "flagAttivo";
      filter.value = false;
      filter.condition = TableServerFilterCondition.NOT_EQ;
      filter.type = TableServerFilterType.FILTER_LIST;

      paramFilter.push(filter);

      params.filter = transformFiltersToServer(paramFilter);

      params.includes = ["Anagrafica", "TipoSeniority", "TipoProfiloLavorativo", "LogAzioneCandidato_List", 
      "CandidatoLingua_List.Lingua","CandidatoSkill_List.Skill","AspettoEconomicoCandidato_List.RalDesiderataCandidato"];
      return super.search2(params, customTable);
    }
  }

  find(id: any, customTable?: string): Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDCANDIDATO: id
      }
    }
    return this.exec('fn_GetDatiCandidato', params);
  }

  add(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_InsertCandidato', execParams);
  }

  update(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1
    };
    return super.exec('sp_UpdateCandidato', execParams);
  }

  del(params: CrudNetUpdateRequest<any>, customTable?: string): Observable<CrudNetBaseResponse<any>>{

    const p = new CrudNetUpdateFieldRequest<any>();
    p.entity = {...params.entity,flagAttivo: false};
    p.filter = {expression: 'idCandidato==@0', expressionValues: [{value: params.entity.idCandidato}]};
    return this.updateflds(p);
  }
  
  searchLookup(
    params: CrudNetSearchRequest<any>,
    customTable?: any
  ): Observable<CrudNetResultResponse<any>> {
    return super.search2(params, customTable);
  }

  getValueForSelect(
    id: any,
    customTable?: string
  ): Observable<CrudNetResultResponse<any>> {
    if (customTable) {
      return super.find(id, customTable);
    }
  }

  getSkillList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "Skill")
  }


}
