import { element } from 'protractor';
import { map, tap } from 'rxjs/operators';
import { RichiesteService } from './../../richieste/richieste.service';
import { Component, DebugEventListener, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faEdit, faEye, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CrudNetFilterExpression, CrudNetRepo, CrudNetUpdateRequest, CrudNetViewMode } from 'crudnet-amgular';
import { I18NextPipe } from 'angular-i18next';
import { Observable, of } from 'rxjs';
import { TableServerActionIntent, TableServerColumn, TableServerColumnType, TableServerConfig, TableServerFilterItem, TableServerSearch, TableServerColumnRender } from 'utils';
import { ArchivioCandidatiCustomCrudComponent } from '../../archivio-candidati/archivio-candidati-custom-crud/archivio-candidati-custom-crud.component';

@Component({
  selector: 'app-richieste-in-lavorazione-custom-crud',
  templateUrl: './richieste-in-lavorazione-custom-crud.component.html',
  styleUrls: ['./richieste-in-lavorazione-custom-crud.component.css']
})
export class RichiesteInLavorazioneCustomCrudComponent implements OnInit {
    /**
   * Crudnet repo for table or view
   */
  @Input() service: RichiesteService;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;
  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
   /**
   * method to fire a new search from server
   */
  @Output() search: EventEmitter<TableServerSearch> = new EventEmitter<TableServerSearch>();

  viewModes = CrudNetViewMode;
  defaultCrudValues;
  customSubmitParams: any[] = [];
  currentIcon = faEye;
  closeIcon = faTimes;
  hideTitle = false;

  element = null;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW, TableServerActionIntent.EDIT];
  tableFilters: TableServerFilterItem[] = [];


  constructor() {

    this.viewMode = CrudNetViewMode.VIEW;
   
    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];
    
    const column1 = new TableServerColumn();
    column1.label = "NOME";
    column1.data = 'nome';
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = "COGNOME";
    column2.data = 'cognome';
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = 'Ultima Azione';
    column3.data = '';
    this.tableConfig.columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = 'Tipo Ultima Azione';
    column4.data = '';
    this.tableConfig.columns.push(column4);

    const column5 = new TableServerColumn();
    column5.label = '';
    column5.data = '';
    column5.actions = this.tableActions;
    column5.type = TableServerColumnType.COLUMN_ACTION;
    column5.render = (row,column,viewMode,closeModal) => {
      const ret = new TableServerColumnRender();
      ret.component = ArchivioCandidatiCustomCrudComponent;
      ret.inputs = {
        service: this.service, //TODO archivio service mappare in input
        viewMode: this.getViewModeFromTable(viewMode),
        idCurrent: row["id" + this.service.getTable()],
        lookupFilters: this.lookupFilters,
        refFieldMap: this.refFieldMap,
        defaultValues: this.defaultValues,
        submit: this.submit,
        customSubmitParams: this.customSubmitParams,
        
        /**
         * mandiamo in input sia la row che gli input per i custom component
         */
        row,
      };

      const closeEmitter = this.close;

      const successEmitter = this.success;
      ret.outputs = {
        close: closeEmitter,
        success: successEmitter,
      }; 
      return ret;
    }
    this.tableConfig.columns.push(column5);

   }

     /**
   * mapping from tableserver viewmode to crudnetviewmode
   */
  getViewModeFromTable(viewMode: TableServerActionIntent): CrudNetViewMode {
    if (viewMode == TableServerActionIntent.VIEW) {
      return CrudNetViewMode.VIEW;
    }
    if (viewMode == TableServerActionIntent.EDIT) {
      return CrudNetViewMode.EDIT;
    }
    return CrudNetViewMode.DELETE;
  }

  ngOnInit() { 
    this.element = this.row.Impiegato.Anagrafica.nome;
  }

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  getData(param: TableServerSearch){
    this.tableRow = this.service.getDataMock().pipe(
      tap((res) => {
        this.tableConfig.totalItems = res.length;
      })
    )

  }

}
