import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Icon} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-custom-action-button',
  templateUrl: './custom-action-button.component.html',
  styleUrls: ['./custom-action-button.component.css']
})
export class CustomActionButtonComponent implements OnInit {
  @Input() row: any;
  @Input() actionIcon: Icon;
  @Input() buttonClass: string;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  onAction() {
    this.action.emit(this.row);
  }

}
