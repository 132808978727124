import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CrudNetFilterExpression, CrudNetRepo, CrudNetSearchRequest, CrudNetUpdateRequest, CrudNetViewMode, TypeaheadSearch } from 'crudnet-amgular';
import { Observable } from 'rxjs';
import { faPlus, faTrash, faEdit, faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PROFILES } from "../../../constants";
import { map } from 'rxjs/operators';
import { join } from '@amcharts/amcharts4/core';
import { RichiesteService } from '../richieste.service';

@Component({
  selector: 'app-tichieste-custom-crud',
  templateUrl: './richieste-custom-crud.component.html',
  styleUrls: ['./richieste-custom-crud.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RichiesteCustomCrudComponent implements OnInit {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: CrudNetRepo<any>;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;

  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  viewModes = CrudNetViewMode;
  selectedImpiegato;
  defaultCrudValues;
  customSubmitParams: any[] = [];
  currentIcon = faPlus;
  closeIcon = faTimes;
  hideTitle = false;
  profiles = PROFILES;
  flagContinuativa;

  sedeCompetenza = null;
  cliente = null;
  referenteCliente = null;
  provinciaSede = null;
  comuneSede = null;
  ambitoLavorativo = null;
  tariffaVendita = null;
  tipologiaLavorativa = null;
  profilo = null;
  tipologiaProfilo = null;
  seniority = null;
  priorita = null;
  codiceRiferimento = null;
  codiceIdentificativo = null;
  skillPrincipali = null;
  skillSecondarie = null;
  visibilita = null;
  continuativa = null;
  lingueRichieste = null;
  statoRichiesta = null;
  note = null;
  skillList = null;
  skillSecondaryList = [];
  lingueList = [];
  idRichiesta = null;
  loading = true;
  skillPrimarie = null;
  codiceStatoRichiesta = null;

  ambitoLavorativoDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: 'descrizione.Contains(@0)',
        expressionValues: [{ value: typedValue }]
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter
    };
    return this.service.search(searchRequest, "TipoSettoreLavorativo").pipe(
      map(res => {

        return res.result;
      })
    );
  }
  provinciaDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: 'descrizione.Contains(@0)',
        expressionValues: [{ value: typedValue }]
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter
    };
    return this.service.search(searchRequest, "Provincia").pipe(
      map(res => {

        return res.result;
      })
    );
  }
  comuneDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: 'descrizione.Contains(@0) && fkIdProvincia = @1',
        expressionValues: [{ value: typedValue }, { value: this.provinciaSede.idProvincia }]
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter
    };
    return this.service.search(searchRequest, "Comune").pipe(
      map(res => {

        return res.result;
      })
    );
  }
  profiloLavorativoDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: 'descrizione.Contains(@0)',
        expressionValues: [{ value: typedValue }]
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter
    };
    return this.service.search(searchRequest, "TipoProfiloLavorativo").pipe(
      map(res => {

        return res.result;
      })
    );
  }
  exec: any;

  constructor(public richiestaService: RichiesteService) { }

  ngOnInit() {

    this.setSkill();
    this.setLingue();

    if (this.viewMode === this.viewModes.CREATE) {
      this.setPosizioneAperta()

    } else if ((this.viewMode === this.viewModes.EDIT || this.viewMode === this.viewModes.VIEW) && this.row) {

      this.row.ReferenteCliente.nominativo = this.row.ReferenteCliente.nome + " " + this.row.ReferenteCliente.cognome;
      this.row.szLingueArray = [
        ...this.row.RichiestaLingua_List ? this.row.RichiestaLingua_List.map(el => el.Lingua) : []
      ];
      this.skillList = [
        ...this.row.RichiestaSkill_List ? this.row.RichiestaSkill_List.map((el) => {
          el.Skill.fkIdTipoCompetenzaRichiesta = el.fkIdTipoCompetenzaRichiesta
          return el.Skill
        }) : []
      ];

      this.loading = false;
      this.selectedImpiegato = {
        idImpiegato: this.row.Impiegato.idImpiegato,
        nome: this.row.Impiegato.Anagrafica.nome,
        cognome: this.row.Impiegato.Anagrafica.cognome
      };
      this.statoRichiesta = this.row.StatoRichiesta;
      this.priorita = this.row.TipoPriorita;
      this.cliente = this.row.Cliente;
      this.referenteCliente = this.row.ReferenteCliente;
      this.codiceRiferimento = this.row.codiceRiferimento;
      this.sedeCompetenza = this.row.Sede;
      this.provinciaSede = this.row.Comune.Provincia;
      this.comuneSede = this.row.Comune;
      this.codiceIdentificativo = this.row.codiceIdentificativo;
      this.ambitoLavorativo = this.row.TipoSettoreLavorativo;
      this.tipologiaLavorativa = this.row.TipologiaLavoro;
      this.profilo = this.row.TipoProfiloLavorativo;
      this.tipologiaProfilo = this.row.tipologiaProfilo;
      this.seniority = this.row.TipoSeniority;
      this.skillPrimarie = this.skillList.filter(sk => sk.fkIdTipoCompetenzaRichiesta == 1);
      this.skillSecondarie = this.skillList.filter(sk => sk.fkIdTipoCompetenzaRichiesta == 2);
      this.lingueRichieste = this.row.szLingueArray;
      this.note = this.row.note;
      this.tariffaVendita = this.row.tariffaVendita;
      this.visibilita = this.row.visibilitaMesi == -1 ? null : this.row.visibilitaMesi;
      this.continuativa = this.row.visibilitaMesi == -1;
    }
    else if (this.viewMode === this.viewModes.DELETE && this.row) {


      this.idRichiesta = this.row.idCurrent;
      this.codiceStatoRichiesta = "E";
    }
    else {
      this.loading = false;
    }
    this.currentIcon = this.getIcon();
  }

  resetComune(event) {
    if (!this.provinciaSede) {
      this.comuneSede.displayValue = " ";
      this.loading = false;
    }
  }

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  onImpiegatoChange(ev) {
    this.selectedImpiegato = ev;
    /*     this.customSubmitParams = ev.idImpiegato; */
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  setSkill() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    this.service.search(searchRequest, "Skill").subscribe(
      res => {
        this.skillList = res.result;
        this.skillSecondaryList = res.result;
      });
  }

  setLingue() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    this.service.search(searchRequest, "Lingua").subscribe(
      res => {
        this.lingueList = res.result;
      });
  }

  setPosizioneAperta() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };
    this.service.search(searchRequest, "StatoRichiesta").subscribe(
      res => {
        this.statoRichiesta = res.result[0];
        this.loading = false;
      });
  }

  impiegatoDisplayValue = (imp) => {
    if (imp) {
      return imp.nome + ' ' + imp.cognome;
    }
  }

  mapToServer(): any {
    this.lingueRichieste = [
      ...this.lingueRichieste ? this.lingueRichieste.map(el => el.idLingua) : []
    ].join(",");

    this.skillList = [
      ...this.skillPrimarie ? this.skillPrimarie.map(el => el.idSkill + "-1") : [],
      ...this.skillSecondarie ? this.skillSecondarie.map(el => el.idSkill + "-2") : []
    ].join(",");

    this.visibilita = this.continuativa ? -1 : this.visibilita

    if (this.viewMode === this.viewModes.DELETE && this.row) {
      return {
        IDRICHIESTA: this.idCurrent,
        CODICESTATORICHIESTA: "E"
      }
    } else {
      return {
        idStatoRichiesta: this.statoRichiesta && this.statoRichiesta.idStatoRichiesta ? this.statoRichiesta.idStatoRichiesta : null,
        idRichiesta: this.idCurrent,
        idReferenteCliente: this.referenteCliente && this.referenteCliente.idReferenteCliente ? this.referenteCliente.idReferenteCliente : null,
        idCliente: this.cliente && this.cliente.idCliente ? this.cliente.idCliente : null,
        idSedeCompetenza: this.sedeCompetenza && this.sedeCompetenza.idSede ? this.sedeCompetenza.idSede : null,
        idComuneSedeLavorativa: this.comuneSede && this.comuneSede.idComune ? this.comuneSede.idComune : null,
        idImpiegatoRichiedente: this.selectedImpiegato && this.selectedImpiegato.idImpiegato ? this.selectedImpiegato.idImpiegato : null,
        idTipoProfiloLavorativo: this.profilo && this.profilo.idTipoProfiloLavorativo ? this.profilo.idTipoProfiloLavorativo : null,
        idTipoSeniority: this.seniority && this.seniority.idTipoSeniority ? this.seniority.idTipoSeniority : null,
        idTipoSettoreLavorativo: this.ambitoLavorativo && this.ambitoLavorativo.idTipoSettoreLavorativo ? this.ambitoLavorativo.idTipoSettoreLavorativo : null,
        idTipoPriorita: this.priorita && this.priorita.idTipoPriorita ? this.priorita.idTipoPriorita : null,
        codiceRiferimento: this.codiceRiferimento,
        visibilitaMesi: this.visibilita,
        note: this.note,
        szLingueArray: this.lingueRichieste,
        szSkillsArray: this.skillList,
        tariffaVendita: this.tariffaVendita,
        idTipologiaLavoro: this.tipologiaLavorativa && this.tipologiaLavorativa.idTipologiaLavoro ? this.tipologiaLavorativa.idTipologiaLavoro : null,
        codiceIdentificativo: this.codiceIdentificativo,
        tipologiaProfilo: this.tipologiaProfilo,
        numeroRisorse: null,
        titolo: null
      };
    }
  }

  // tslint:disable-next-line:variable-name
  onSubmit() {

    const entity = this.mapToServer();
    const p = new CrudNetUpdateRequest();
    let obj = new Observable<any>();


    p.entity = entity;
    if (this.viewMode === CrudNetViewMode.CREATE) {
      obj = this.service.add(p);
    } else if (this.viewMode === CrudNetViewMode.EDIT) {
      obj = this.service.update(p);
    } else if (this.viewMode === CrudNetViewMode.DELETE) {
      obj = this.service.del(p);
    }
    obj.subscribe(res => {
      if (res.error) {
        this.error.emit(res.message);
      } else {
        this.success.emit('OK');
      }
      this.close.emit();
    });
  }

}
