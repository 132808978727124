import { CrudNetFilterExpression } from 'crudnet-amgular';
import { ArchivioCandidatiService } from "./archivio-candidati.service";
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType,
  ModalService, TableServerFilter, TableServerFilterCondition, TableServerColumnSortDirection, TableServerCheckSelection, TableServerSearch
} from "utils";

import { CrudNetViewMode, CrudnetHeaderButton, CrudNetSearchRequest, TypeaheadSearch, transformFiltersToServer, CrudNetUpdateRequest } from "crudnet-amgular";
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ArchivioCandidatiCustomCrudComponent } from './archivio-candidati-custom-crud/archivio-candidati-custom-crud.component';
import { Observable, of } from "rxjs";
import { CustomDateRendererComponent } from "src/app/components/custom-date-renderer/custom-date-renderer.component";
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: "app-archivio-candidati",
  templateUrl: "./archivio-candidati.component.html",
  styleUrls: ["./archivio-candidati.component.css"],
  encapsulation:ViewEncapsulation.None
})
export class ArchivioCandidatiComponent implements OnInit {
  /**
  * event fire on success form submitting
  */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
  * event fire on error form submitting
  */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  idTable = "idTable";

  viewModes = CrudNetViewMode;
  viewMode: CrudNetViewMode;

  customCRUD: any;

  currentIcon = faPlus;
  closeIcon = faTimes;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.DELETE, TableServerActionIntent.EDIT];
  tableFilters: TableServerFilterItem[] = [];

  idAssegnazioneRichiesta='idModal';
  addedButton:CrudnetHeaderButton[]=[{
    text:'Assegna a Richiesta',
    action: this.assegnaRichiesta.bind(this)
  }]

  selectedDataCheckbox: any[] = [];
  columnSelection: TableServerColumn = {
    label: "",
    sortable: false,
    sortDirection: TableServerColumnSortDirection.NO_SORT,
    data: "selection",
    hideSelectAll: true,
    type: TableServerColumnType.COLUMN_CHECKBOX,
    onCheck: (selectedData: TableServerCheckSelection) => {
      this.selectedDataCheckbox = selectedData.data.map(el => el.idCandidato);
      this.disableButton(this.idTable);
    },
  };

  assegnazioneRichiesta = null;
  selectRichiesta:any [] = [];

  constructor(public archivioCandidatiService: ArchivioCandidatiService, private modalService:ModalService) {
    this.getSkillList();
    this.viewMode = CrudNetViewMode.VIEW;
    this.customCRUD = ArchivioCandidatiCustomCrudComponent;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    this.tableConfig.columns.push(this.columnSelection);

    const column1 = new TableServerColumn();
    column1.label = "NOME";
    column1.data = "Anagrafica.nome";
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = "COGNOME";
    column2.data = "Anagrafica.cognome";
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = "TIPOLOGIA";
    column3.data = "TipoProfiloLavorativo.descrizione";
    this.tableConfig.columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = "SENIORITY";
    column4.data = "TipoSeniority.descrizione";
    this.tableConfig.columns.push(column4);

    const column6 = new TableServerColumn();
    column6.label = "Ultima Azione";
    column6.data = "LogAzioneCandidato_List.dataCreazione";
    column6.type = TableServerColumnType.COLUMN_CUSTOM;
    column6.render = (row, column) => {
      const render = new TableServerColumnRender();
      const date = row.LogAzioneCandidato_List.length? row.LogAzioneCandidato_List[row.LogAzioneCandidato_List.length-1].dataCreazione : null
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: date
      };
      return render;
    }; 
    this.tableConfig.columns.push(column6);

    const filter1 = new TableServerFilterItem();
    filter1.label = "Nome";
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = "Anagrafica.nome";
    this.tableFilters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = "Cognome";
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = "Anagrafica.cognome";
    this.tableFilters.push(filter2);

    const filter3 = new TableServerFilterItem();
    filter3.label = "RAL Desiderata Da";
    filter3.type = TableServerFilterType.FILTER_NUMBER;
    filter3.data = "AspettoEconomicoCandidato_List.RalDesiderataCandidato.ralDa";
    this.tableFilters.push(filter3);

    const filter4 = new TableServerFilterItem();
    filter4.label = "RAL Desiderata A";
    filter4.type = TableServerFilterType.FILTER_NUMBER;
    filter4.data = "AspettoEconomicoCandidato_List.RalDesiderataCandidato.ralA";
    this.tableFilters.push(filter4);

    const filter5 = new TableServerFilterItem();
    filter5.label = "Tipologia";
    filter5.type = TableServerFilterType.FILTER_TEXT;
    filter5.data = "TipoProfiloLavorativo.descrizione";
    this.tableFilters.push(filter5);

    const filter6 = new TableServerFilterItem();
    filter6.label = "Seniority";
    filter6.type = TableServerFilterType.FILTER_TEXT;
    filter6.data = "TipoSeniority.descrizione";
    this.tableFilters.push(filter6);

    const filter7 = new TableServerFilterItem();
    filter7.label = 'Skill';
    filter7.type = TableServerFilterType.FILTER_MULTIPLE;
    filter7.data = 'CandidatoSkill_List';
    filter7.options = [];
    this.tableFilters.push(filter7);

  }

  ngOnInit() {}

  ngAfterViewInit(){
    this.disableButton(this.idTable);
  }
 
  getSkillList() {
    this.archivioCandidatiService.getSkillList().subscribe(
      res => {
        let skillList = this.tableFilters.filter(el => {
          return el.data === "CandidatoSkill_List";
        })
        skillList.map(skill => {
          return skill.options = res.result
        })
      });
  }

  assegnaRichiesta(){
    this.modalService.open(this.idAssegnazioneRichiesta);
  }

  getRichieste: TypeaheadSearch = (typedValue) => {
    let filter:CrudNetFilterExpression = null;
  
    if (typedValue && typedValue.length) {
      let tableServerFilter = new TableServerFilter();
      tableServerFilter.type= TableServerFilterType.FILTER_TEXT;
      tableServerFilter.data="Cliente.codiceCliente";
      tableServerFilter.condition = TableServerFilterCondition.LIKE;
      tableServerFilter.value=typedValue;
      let tableServerFilterTP = new TableServerFilter();
      tableServerFilterTP.type= TableServerFilterType.FILTER_TEXT;
      tableServerFilterTP.data="tipologiaProfilo";
      tableServerFilterTP.condition = TableServerFilterCondition.LIKE;
      tableServerFilterTP.value=typedValue;
      filter=transformFiltersToServer([tableServerFilter,tableServerFilterTP]);
      filter.expression=filter.expression.split('&&').join('||');
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      includes: ["Cliente"],
      pageSize: 20,
      filter
    };
    return this.archivioCandidatiService.searchLookup(searchRequest, "Richiesta").pipe(
      map((res) => {
        return res.result.map(server=>{
          this.selectRichiesta = server.idRichiesta;
          return {...server, label:server.Cliente.codiceCliente + ' - Richiesta ' + server.tipologiaProfilo + ' del ' + moment(server.dataCreazione).format('DD/MM/YYYY')}
        })
      })
    );
  };

  mapToServer(){
    let app: any = {
      
    }

  }

  onSubmit(){
    const entity = this.mapToServer();
    const p = new CrudNetUpdateRequest();
    let obj = new Observable<any>();

    p.entity = entity;
    console.log(p.entity);
    if (this.viewMode === CrudNetViewMode.CREATE) {
      obj = this.archivioCandidatiService.add(p);
    } else if (this.viewMode === CrudNetViewMode.EDIT) {
      obj = this.archivioCandidatiService.update(p);
    } else if (this.viewMode === CrudNetViewMode.DELETE) {
      obj = this.archivioCandidatiService.del(p);
    }
    obj.subscribe((res) => {
      if (res.error) {
        this.error.emit(res.message);
      } else {
        this.success.emit("OK");
      }
      this.close.emit();
    });
  }


  disableButton(idTable: string){
    let element = document.querySelector('#' + idTable+ ' > div > div:nth-child(1) > div > button');
    if(this.selectedDataCheckbox.length < 1){
      element.setAttribute('disabled', 'disabled');
    } else {
      element.removeAttribute('disabled');
    }
  }



  closeModal() {
    this.modalService.close(this.idAssegnazioneRichiesta);
  }

}
